<template>
  <div
    class="mbom-impact-test px-8 py-10 container"
    style="max-width: 1150px;margin:0 auto;"
  >
    <div class="flex flex-wrap">
      <SideBar class="sm:w-1/5 sm:order-first sm:mt-0 w-full order-last mt-4" />
      <div class="sm:w-4/5 w-full">
        <TitleComponent class="py-8" title="Koenen Test" size="large" />

        <div class="flex flex-wrap mt-8 p-4" style="background-color:#f7f5f4;">
          <div class="w-full">
            <TitleComponent
              class="py-8"
              title="Koenen Apparatus Description"
              size="medium"
            />
            <a
              target="_blank"
              :href="require('../../assets/IPSeries/KoenenDetailed-min.png')"
            >
              <img src="@/assets/IPSeries/KoenenDetailed-min.png" />
            </a>
          </div>
          <div class="w-full">
            <TitleComponent class="py-8" title="Test Purpose" size="medium" />
            <p class="p-light">
              This test is used to determine the sensitiveness of a material to
              the effect of intense heat under vented confinement. In this test,
              the material is placed in a steel container with an orifice plate.
              The test apparatus is then placed in a protective steel box, and
              heated at a specified rate. A series of trials is conducted using
              different sizes of orifices. A “+” reaction is determined by
              examining the container. Conducting three successive “-” reactions
              with an orifice plate size above that which produced a positive
              result concludes the test. This orifice is called the limiting
              diameter. The limiting diameter may be used to evaluate the degree
              of venting required to avoid an explosion in the process. The
              Koenen results can be coupled with the
              <a
                class="link"
                target="_blank"
                href="http://www.smsenergetics.com/testing/modeling-and-analysis"
                >IVM-FC</a
              >
              a control volume thermodynamic model with vent panel parameters to
              estimate the required vent volume.
            </p>
            <TitleComponent class="py-8" title="Equivalence" size="medium" />
            <p class="p-light">
              This test is equivalent to
              <router-link class="link" to="/koenen-test"
                >UN Tests 2 (b)</router-link
              >
              in the UN Manual of Tests and Criteria
            </p>
          </div>
        </div>
        <div class="flex flex-wrap">
          <div class="w-full">
            <TitleComponent class="pt-8" title="Key Parameters" size="medium" />
            <div class="md:px-10 md:pb-10 overflow-x-auto">
              <div>
                <table class="test-table">
                  <tbody>
                    <tr>
                      <th>Key Parameter</th>
                      <th>Objectives</th>
                      <th>Origin</th>
                      <th>Specs</th>
                    </tr>
                    <tr>
                      <td>Confining medium (steel tube)</td>
                      <td>
                        Vessel that can be used as evidence of sample reactivity
                        should the venting capacity of the orifice be exceeded
                        (excessive gas generation rate, orifice plugging, etc.)
                      </td>
                      <td></td>
                      <td>
                        Deep drawn from DC04, A620, or SPCEN sheet steel; 26.5 ±
                        1.5 grams mass, 75 °C 0.5mm length, 0.5 ± 0.05mm wall,
                        and 30 ± 3 MPa quasi-static bursting pressure
                      </td>
                    </tr>
                    <tr>
                      <td>Orifice diameter</td>
                      <td>Provide varying degrees of venting capacity</td>
                      <td></td>
                      <td>1mm orifice</td>
                    </tr>
                    <tr>
                      <td>Heating rate</td>
                      <td>Provide a standard heating rate</td>
                      <td></td>
                      <td>3.3 ± 0.3°K/sec for 27mL of dibutyl phthalate</td>
                    </tr>
                    <tr>
                      <td>Maximum heating time</td>
                      <td>Sufficient time to react the material</td>
                      <td></td>
                      <td>5 minutes</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <TitleComponent class="pt-8" title="Indicators" size="medium" />
            <div class="md:px-10 md:pb-10 overflow-x-auto">
              <table class="test-table">
                <tbody>
                  <tr>
                    <th>Indicators</th>
                    <th>Detection Method</th>
                    <th>Assessment</th>
                  </tr>
                  <tr>
                    <td>Damage to the steel tube and/or closing device</td>
                    <td>
                      Visual post-test examination of the tube and/or tube
                      fragments
                    </td>
                    <td>
                      Limiting diameter (LD) is the orifice at which an
                      explosion (three or more tube fragments) is obtained. The
                      limiting diameter may be used to evaluate the degree of
                      venting required to avoid an explosion in the process.
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="flex flex-wrap pb-6" style="background-color:#f7f5f4;">
          <div class="w-full p-10">
            <TitleComponent class="pt-8" title="Setup Example" size="medium" />
            <a
              :href="require('../../assets/IPSeries/setup-koenen-min.jpg')"
              target="_blank"
            >
              <img
                class="m-auto"
                src="@/assets/IPSeries/setup-koenen-min.jpg"
              />
            </a>
          </div>
          <div class="w-full md:w-1/2 p-4">
            <TitleComponent
              class="pt-8"
              title="No Explosion (-) Example Photo"
              size="medium"
            />
            <a
              :href="require('../../assets/IPSeries/nogo-koenen-min.jpg')"
              target="_blank"
            >
              <img class="m-auto" src="@/assets/IPSeries/nogo-koenen-min.jpg" />
            </a>
          </div>
          <div class="w-full md:w-1/2 p-4">
            <TitleComponent
              class="pt-8"
              title="No Explosion (-) Example Video"
              size="medium"
            />
            <iframe
              class="m-auto"
              src="https://player.vimeo.com/video/166129503?autoplay=0&amp;autopause=0"
              allowfullscreen=""
              title="vimeo166129503"
              allow="autoplay; fullscreen"
              id="player_1"
              name="fitvid0"
            ></iframe>
          </div>
          <div class="w-full md:w-1/2 p-4">
            <TitleComponent
              class="pt-8"
              title="Explosion (+) Example Photo"
              size="medium"
            />
            <a
              :href="require('../../assets/IPSeries/go-koenen-min.jpg')"
              target="_blank"
            >
              <img class="m-auto" src="@/assets/IPSeries/go-koenen-min.jpg" />
            </a>
          </div>
          <div class="w-full md:w-1/2 p-4">
            <TitleComponent
              class="pt-8"
              title="Explosion (+) Example Video"
              size="medium"
            />
            <iframe
              class="m-auto"
              src="https://player.vimeo.com/video/166129504?autoplay=0&amp;autopause=0"
              allowfullscreen=""
              title="vimeo166129504"
              allow="autoplay; fullscreen"
              id="player_2"
              name="fitvid1"
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TitleComponent from "@/components/TitleComponent";
import SideBar from "@/components/IPSeriesSideBar";

export default {
  components: {
    TitleComponent,
    SideBar
  }
};
</script>

<style lang="scss"></style>
